import { Component } from 'react'
import {Teams_GetUserEmail} from "../auth/teamsAuth"
import { verifyMeetingBoosterUrl } from '../auth/meetingBoosterAuth';
import ConfirmMeetingBoosterUrl from './ConfirmMeetingBoosterUrl';
import commonStyles from '../shared/styles/common.module.scss';
import styles from './styles/confirm-meetingbooster-url.module.scss';
import { WithTranslation, withTranslation } from 'react-i18next';
import React from 'react';
import { Loader } from '@fluentui/react-northstar';
import { sendMessageToParent } from '@microsoft/teams-js';

export enum TeamsContext {
    Application = "application",
    Config = "config",
    InMeeting = "in-meeting"
}

interface AppProps extends WithTranslation {
    callback: (url: string, verified: boolean, couldVerifyPreemptively: boolean) => void,
    hideWhenVerified?: boolean,
    verifyContext?: TeamsContext
}
interface AppState {
    hideWhenVerified: boolean
    meetingBoosterUrl: string
    verified: boolean,
    searchingForUrl: boolean
}

class MeetingBooster extends Component<AppProps, AppState> {

    constructor(props: AppProps) {
        super(props);

        this.state = {
            hideWhenVerified: this.props.hideWhenVerified ?? true,
            meetingBoosterUrl: "",
            verified: false,
            searchingForUrl: true
        }

        this.confirmUrlCallback = this.confirmUrlCallback.bind(this);
    }

    async componentDidMount() {
        await this.getMeetingBoosterUrl();
    }

    private confirmUrlCallback(verified: boolean, url: string, couldVerifyPreemptively: boolean = false) {
        this.setState({ verified: verified });
        this.props.callback(url, this.state.verified, couldVerifyPreemptively);

    }

    private GetUrlFromQuery()
    {
        var param = new URLSearchParams(document.location.search).get("MeetingBoosterUrl")
        if (param !== null && param !== "") {
            return param;
        }
        return null;
    }

    
    private async GetUrlFromDns(): Promise<string|null>
    {

        // sendMessageToParent('getContext', (aa: any) => {
        //     console.log(aa);
        //     // if (!context.frameContext) {
        //     //   // Fallback logic for frameContext properties
        //     //   alert("p1");
        //     //   //context.frameContext = GlobalVars.frameContext;
        //     // }
        //     // alert("p");
        //   });

        const authenticationServer = process.env.REACT_APP_AUTHENTICATION_SERVER;
        return new Promise((resolve, reject) => {
            Teams_GetUserEmail().then((email)=> {
                if (email === null || email.indexOf("@") === -1) {
                    console.error("Could not get user's email address: " + email);
                    resolve(null);
                    return;
                }
                var domain = email.substring(email.indexOf("@") + 1);
                fetch(`${authenticationServer}/dns/Lookup?domain=` + domain)
                .then(response => {
                    if (response.ok) {
                        return response.text();
                    } else {
                        throw new Error('Could not get MeetingBooster server url for domain: ' + domain);
                    }
                })
                .then((response) => {
                    resolve(response);
                    //console.log("MBURL:" + response)
                })
                .catch(err => {
                    console.error(err)
                    resolve(null);
                })
            });

        });
    }

    private async getMeetingBoosterUrl() {
        var meetingBoosterUrl: string | null = this.GetUrlFromQuery();
        console.log("%cGetUrlFromQuery: " + meetingBoosterUrl, 'background: #fff; color: #28A526')
        if (meetingBoosterUrl === null) {
            meetingBoosterUrl = localStorage.getItem("meetingBoosterUrl");
            console.log("%cGetUrlFromLocalStorage: " + meetingBoosterUrl, 'background: #fff; color: #28A526')
        }
        if (meetingBoosterUrl === null && localStorage.getItem("disableDns") !== "1") {
            meetingBoosterUrl = await this.GetUrlFromDns();
            console.log("%cGetUrlFromDns: " + meetingBoosterUrl, 'background: #fff; color: #28A526')
        }
        if (meetingBoosterUrl !== null) {
            verifyMeetingBoosterUrl(meetingBoosterUrl).then(verified => {
                if (localStorage.getItem("disableSaveUrl") !== "1")
                    localStorage.setItem("meetingBoosterUrl", meetingBoosterUrl?meetingBoosterUrl:"");
                this.setState({ searchingForUrl: false, meetingBoosterUrl: meetingBoosterUrl?meetingBoosterUrl:"", verified: verified });
                this.confirmUrlCallback(verified, meetingBoosterUrl?meetingBoosterUrl:"", true);
            }).catch(error => {
                this.setState({ searchingForUrl: false, meetingBoosterUrl: meetingBoosterUrl?meetingBoosterUrl:"", verified: false });
                console.log(error);
            });
        } else {
            this.setState({ searchingForUrl: false, meetingBoosterUrl: "" });
        }
    }

    render() {
        const showConfirm = !this.state.verified || (this.state.verified && !this.state.hideWhenVerified);
        const { verifyContext = TeamsContext.Application } = this.props;
        let content, headerText;


        headerText = <></>;
        if (verifyContext === TeamsContext.Application) {
            headerText = <div className={styles['verify-header']}>{this.props.t('url_verifyheader')}</div>;
        }

        if (verifyContext === TeamsContext.InMeeting) {
            headerText = <div className={styles['verify-header-inMeeting']}>{this.props.t('url_verifyheader')}</div>;
        }

        content = <Loader label={this.props.t('url_searching')} />;
        if (!this.state.searchingForUrl && showConfirm) {
            content = <ConfirmMeetingBoosterUrl verifyContext={verifyContext} meetingBoosterUrl={this.state.meetingBoosterUrl} callback={this.confirmUrlCallback} />;
        }


        return (
            <div>
                {showConfirm &&
                    <div className={`${commonStyles.wrapper} `}>
                        {headerText}
                        {content}
                    </div>
                }
                {!this.state.searchingForUrl && this.state.verified &&
                    <>{this.props.children}</>
                }
            </div>
        )
    }
}

export default withTranslation()(MeetingBooster);

