import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  resources: {
    en: {      
      translations: require('./locales/translations.en.json')
    },
    es: {
      translations: require('./locales/translations.es.json')
    },
    de: {
      translations: require('./locales/translations.de.json')
    },
    fr: {
      translations: require('./locales/translations.fr.json')
    },
    da: {
      translations: require('./locales/translations.da.json')
    }
  },
  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = [ 'en', 'es', 'fr', 'de', 'da'];

export default i18n;