import React, { useEffect } from 'react';
import * as microsoftTeams from "@microsoft/teams-js";
import { useTranslation } from 'react-i18next';

/**
 * This component is loaded when the Azure implicit grant flow has completed.
 */
export default function ClosePopup() {
    const { t } = useTranslation();
    useEffect(() => {
        microsoftTeams.initialize();

        //The Azure implicit grant flow injects the result into the window.location.hash object. Parse it to find the results.
        let hashParams = getHashParameters();
        //If consent has been successfully granted, the Graph access token should be present as a field in the dictionary.
        if (hashParams["access_token"]) {
            //Notifify the showConsentDialogue function in Tab.js that authorization succeeded. The success callback should fire. 
            microsoftTeams.authentication.notifySuccess(JSON.stringify(hashParams));
        } else {
            microsoftTeams.authentication.notifyFailure("consent_not_given");
        }
    }, []);

    //Helper function that converts window.location.hash into a dictionary
    function getHashParameters() {
        let hashParams: any = {};
        window.location.hash.substr(1).split("&").forEach(function (item) {
            let [key, value] = item.split('=');
            hashParams[key] = decodeURIComponent(value);
        });
        return hashParams;
    }

    return (
        <div>
            <h1>{t("consent_complete")}</h1>
        </div>
    );

}