import { Component } from 'react';
import MeetingDetails from '../../shared/MeetingDetails';
import MeetingsList from './MeetingsList';
import { MeetingBoosterAPI } from '../../api/meetingBoosterAPI';
import { Button, carouselNavigationItemSlotClassNames, ChevronStartIcon } from '@fluentui/react-northstar';
import commonStyles from '../../shared/styles/common.module.scss';
import React from 'react';

interface AppProps {
    meetingBoosterUrl: string
};
interface AppState {
    meetings: MeetingDTO[],
    meetingsFilter: any[],
    tableSettings: TableSettings,
    loadingMeetings: boolean,
    selectedMeeting: MeetingDTO | null,
    open: boolean
};

export default class Meetings extends Component<AppProps, AppState> {
    private meetingBoosterApi: MeetingBoosterAPI;

    constructor(props: AppProps) {
        super(props);
        this.state = {
            meetings: [],
            meetingsFilter: [{
                    "name": "PastMeetingsFilter",
                    "value": [false]
                },
                {
                "name":"DateRangeFilter",
                "value": [new Date(Date.now() - 1000*60*60*24*7).toJSON(), new Date(Date.now() + 1000*60*60*24*14).toJSON()]
                }
            ],
            tableSettings: {
                CurrentPage: 1,
                PageSize: 12,
                SortDescending: false,
                SortColumn: "sort_date"
            },
            loadingMeetings: false,
            selectedMeeting: null,
            open: false
        }
        this.selectMeeting = this.selectMeeting.bind(this);
        this.deselectMeeting = this.deselectMeeting.bind(this);
        this.getMeetingBoosterMeetings = this.getMeetingBoosterMeetings.bind(this);
        this.changePage = this.changePage.bind(this);
        this.changePastFilter = this.changePastFilter.bind(this);

        this.meetingBoosterApi = new MeetingBoosterAPI(this.props.meetingBoosterUrl);
    }

    private selectMeeting(meeting: MeetingDTO) {
        this.setState({ selectedMeeting: meeting });
    }

    private deselectMeeting() {
        this.setState({ selectedMeeting: null });
        this.getMeetingBoosterMeetings([]);
    }

    private async getMeetingBoosterMeetings(filter: any[]) {
        try {
            let newFilter = filter.length === 0 ? this.state.meetingsFilter : this.updateFilter(filter);

            this.setState({ loadingMeetings: true, meetingsFilter: newFilter }, async () => {
                let meetingBoosterMeetings = await this.meetingBoosterApi.getMeetingBoosterMeetings(this.state.meetingsFilter, this.state.tableSettings);
                this.setState({ meetings: meetingBoosterMeetings.meetings, loadingMeetings: false, tableSettings: meetingBoosterMeetings.settings });
            });

        } catch (error) {
            console.log(error);
        }
    }

    private updateFilter(newFilters: any[]) {
        //console.log(newFilters);
        let stateFiltersCopy = [...this.state.meetingsFilter];
        newFilters.forEach(newFilter => {
            let index = stateFiltersCopy.findIndex(({ name }) => name === newFilter.name);
            if (index === -1) {
                stateFiltersCopy.push(newFilter);
            } else {
                stateFiltersCopy[index] = newFilter;
            }
        });
        return stateFiltersCopy;
    }

    private changePage(data: { selected: number }) {
        let settings = this.state.tableSettings;
        settings.CurrentPage = data.selected + 1;
        this.setState({tableSettings: settings});
        this.getMeetingBoosterMeetings([]);
    }

    // Clicking tab on upcoming / past meetings
    private changePastFilter(newFilter: any) {
        var sortDescending = true;
        var d1: Date | null = null;
        var d2: Date | null = null;

        var mf = this.state.meetingsFilter.filter((f) => {return f.name == "DateRangeFilter"})[0];

        if (newFilter.name == "PastMeetingsFilter" && newFilter.value[0] == false) {
            sortDescending = false;
            // upcoming meetings
            // set default days to be 1 week before and two weeks after today
            mf["value"] = [new Date(Date.now() - 1000*60*60*24*7).toJSON(), new Date(Date.now() + 1000*60*60*24*14).toJSON()];
        } else {
            // past meetings
            // set default days to be null
            mf["value"] = [new Date("1980-01-01").toJSON(), new Date("2399-01-01").toJSON()];
        }

        
        
        
    //     : [{
    //         "name": "PastMeetingsFilter",
    //         "value": [false]
    //     },
    //     {
    //     "name":"DateRangeFilter",
    //     "value": [new Date(Date.now() - 1000*60*60*24*7).toJSON(), new Date(Date.now() + 1000*60*60*24*14).toJSON()]
    //     }
    // ],

      
        this.setState({tableSettings: {
            CurrentPage: 1,
            PageSize: 12,
            SortColumn: this.state.tableSettings.SortColumn,
            SortDescending: sortDescending
        }}, () => {
            this.getMeetingBoosterMeetings([newFilter]);
        });
    }

    render() {
        return (
            <div>
                <div style={{ display: this.state.selectedMeeting === null ? 'block' : 'none' }}>
                    <MeetingsList getMeetingBoosterMeetings={this.getMeetingBoosterMeetings}
                        selectMeeting={this.selectMeeting}
                        loadingMeetings={this.state.loadingMeetings}
                        meetings={this.state.meetings}
                        changePage={this.changePage}
                        currentPage={this.state.tableSettings.CurrentPage - 1}
                        pageCount={this.state.tableSettings.PageCount}
                        changePastFilter={this.changePastFilter}
                    />
                </div>
                {this.state.selectedMeeting !== null &&
                    <div className={commonStyles.wrapper}>
                        <div style={{ float: "left" }}>
                            <Button icon={<ChevronStartIcon size={'larger'} />} iconOnly text onClick={this.deselectMeeting} />
                        </div>
                        <MeetingDetails meetingBoosterAPI={this.meetingBoosterApi} meeting={this.state.selectedMeeting} />
                    </div>
                }
            </div>
        )
    }
}
