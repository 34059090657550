import { useEffect, useState } from 'react'
import { CloseIcon, Datepicker, Dialog } from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';
import React from 'react';

interface Props {
    show: boolean,
    onFilterChange: (filter: any[]) => void,
    onClose: () => void,
    mfd: any
}

export default function MeetingsFilter(props: Props) {

    const [fromDate, setFromDate] = useState<Date | undefined>(undefined);
    const [toDate, setToDate] = useState<Date | undefined>(undefined);


    useEffect(() => {
        // register eventListener on each state update
        return () => {
          // unregister eventListener
        };
      }, [props.mfd]);

    function fromDateHandler(date: any, data: any) {
        setFromDate(data.value);
    }

    function toDateHandler(date: any, data: any) {
        setToDate(data.value);
    }

    function applyFilter() {
        let filter: any[] = [];

        var fd = fromDate;
        var td = toDate;




        if (fd == undefined || fd == null) {
            fd = new Date();
            fd.setFullYear(1980);
        }
        if (td == undefined || td == null) {
            td = new Date();
            td.setFullYear(3000);
        }
        filter.push({
            "name": "DateRangeFilter",
            "value": [fd, td]
        });

        props.onFilterChange(filter);
        props.onClose();
    }
    const { t } = useTranslation();

    let content = (
        <div>
            {t('meetingsfilter_from')} <Datepicker input={{ clearable: true }} selectedDate={fromDate} onDateChange={fromDateHandler} />
            {t('meetingsfilter_to')} <Datepicker input={{ clearable: true }} selectedDate={toDate} onDateChange={toDateHandler} />
        </div>
    )

    return <Dialog open={props.show} content={content} closeOnOutsideClick
        confirmButton={{ content: t('buttontext_apply'), onClick: applyFilter }}
        cancelButton={{ content: t('buttontext_cancel'), onClick: props.onClose }}
        variables={{ rootWidth: "350px" }}
        header={t('meetingsfilter_header')}
        headerAction={{
            icon: <CloseIcon />,
            title: t('buttontext_close'),
            onClick: () => props.onClose(),
        }}
    />
}