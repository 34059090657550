export class CustomError extends Error {
    constructor(message: string) {
        super(message);
        this.name = "CustomError";
    }
}

export class CouldNotFindMappingError extends Error {
    constructor(message?: string) {
        super(message);
        this.name = "CouldNotFindMappingError";
    }
}

export class AuthServerDidNotRespond extends Error {
    constructor(message: string = "Authorization server did not respond. Please try again.") {
        super(message);
        this.name = "AuthServerDidNotRespond";
    }
}

export class ConsentNotGivenError extends Error {
    constructor(message: string = "Consent was not given.") {
        super(message);
        this.name = "ConsentNotGivenError";
    }
}