import React, { useState } from "react";
import "../../App.css";
import * as microsoftTeams from "@microsoft/teams-js";
import MeetingBooster from '../../general/MeetingBooster';
import './styles/global.scss';
import { TeamsContext } from '../../general/MeetingBooster';
import { useTranslation } from "react-i18next";

export default function TabConfig(props: any) {

  const [preemptivelyVerified, setPreemptivelyVerified] = useState(false);
  const { t } = useTranslation();

  microsoftTeams.appInitialization.notifySuccess();

  microsoftTeams.settings.registerOnSaveHandler((saveEvent) => {
    const teamsServerUrl = process.env.REACT_APP_TEAMS_SERVER;

    microsoftTeams.settings.setSettings({
      suggestedDisplayName: "MeetingBooster",
      entityId: "meetingTab",
      websiteUrl: `${teamsServerUrl}/prepostmeeting`,
      contentUrl: `${teamsServerUrl}/prepostmeeting`
    });
    saveEvent.notifySuccess();
  });

  function urlConfirmed(url: string, verified: boolean, couldVerifyPreemptively: boolean) {
    setPreemptivelyVerified(couldVerifyPreemptively);
    microsoftTeams.settings.setValidityState(verified);
  }

  return (
    <div>
      <MeetingBooster callback={urlConfirmed} hideWhenVerified={false} verifyContext={TeamsContext.Config} />
      {preemptivelyVerified &&
        <span>The URL could be verified preemptively, please press 'Save' to continue.</span>
      }
    </div>
  );
}