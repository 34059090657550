import { Component } from 'react'
import * as microsoftTeams from "@microsoft/teams-js";
import MeetingDetails from '../../shared/MeetingDetails';
import MeetingBooster from '../../general/MeetingBooster';
import { MeetingBoosterAPI } from '../../api/meetingBoosterAPI';
import { CouldNotFindMappingError } from '../../api/meetingBoosterErrors';
import commonStyles from '../../shared/styles/common.module.scss';
import NoMappedMeetingError from './NoMappedMeetingError';
import React from 'react';

interface AppState {
    meeting: MeetingDTO | undefined,
    error: boolean,
    errorDisplay: JSX.Element,
};

export default class PrePostMeetingTab extends Component<{}, AppState> {

    private meetingBoosterAPI!: MeetingBoosterAPI;

    constructor(props: {}) {
        super(props);

        this.state = {
            meeting: undefined,
            error: false,
            errorDisplay: <></>
        }

        this.urlConfirmed = this.urlConfirmed.bind(this);
    }


    private urlConfirmed(url: string, verified: boolean) {
        if (verified) {
            this.meetingBoosterAPI = new MeetingBoosterAPI(url);

            this.getMeetingBoosterMeeting();
        }
    }

    private getMeetingBoosterMeeting() {
        microsoftTeams.getContext(async (context: microsoftTeams.Context) => {
            try {
                let meetingBoosterMeetingId = await this.meetingBoosterAPI.getMeetingBoosterMeetingId(context.meetingId as string);
                let meetingBoosterMeeting = await this.meetingBoosterAPI.getMeetingBoosterMeeting(meetingBoosterMeetingId.MeetingGuid);

                const meeting: MeetingDTO = {
                    Id: meetingBoosterMeeting.Id,
                    Host: meetingBoosterMeeting.Host,
                    Title: meetingBoosterMeeting.Title,
                    Start: new Date(meetingBoosterMeeting.Start),
                    End: new Date(meetingBoosterMeeting.End),
                    Invitations: meetingBoosterMeeting.Invitations,
                    IsRecurring: false
                }
                this.setState({ meeting: meeting });
            } catch (error) {
                if (error instanceof CouldNotFindMappingError) {
                    this.setState({ error: true, errorDisplay: <NoMappedMeetingError onClick={() => this.getMeetingBoosterMeeting()} /> })
                } else {
                    console.log(error);
                }
            }
        });
    }

    render() {
        let content = <></>
        if (this.state.meeting !== undefined) {
            content = <div className={commonStyles.wrapper}>
                <MeetingDetails meeting={this.state.meeting} meetingBoosterAPI={this.meetingBoosterAPI} />
            </div>;
        }
        if (this.state.error) {
            content = <div className={commonStyles.wrapper}>
                {this.state.errorDisplay}
            </div>
        }

        return (
            <MeetingBooster callback={this.urlConfirmed}>
                {content}
            </MeetingBooster>
        )
    }
}
