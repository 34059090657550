import React from 'react';
import { useState } from 'react'
import Topic from './Topic';
import TopicDetails from './TopicDetails';
import styles from './styles/agenda.module.scss';
import { useTranslation } from 'react-i18next';

interface AppProps {
    agenda: AgendaDTO,
    selectedTopic: number
}

export default function Agenda(props: AppProps) {

    const [topicDetailsShown, setTopicDetailsShown] = useState<TopicDTO | undefined>(undefined);
    const [t] = useTranslation();

    function sortTopicsByNumbering(): TopicDTO[] {
        let topics = props.agenda.topics;
        topics.sort((first, second) => first.Numbering.localeCompare(second.Numbering, 'en', { numeric: true }));

        return topics;
    }

    if (topicDetailsShown !== undefined) {
        return <TopicDetails topic={topicDetailsShown} goBack={() => setTopicDetailsShown(undefined)} />;
    }

    return (
        <div>
            <div className={styles.agenda}>{t('agenda_header')}</div>
            {sortTopicsByNumbering().map(topic => <Topic topic={topic} selected={props.selectedTopic === topic.Id} key={topic.Id} onClick={() => setTopicDetailsShown(topic)} />)}
        </div>
    )
}