import { Provider, ThemePrepared } from "@fluentui/react-northstar";
import { themeNames } from "@fluentui/react-teams";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import Privacy from "./Privacy";
import TermsOfUse from "./TermsOfUse";
import Tab from "./applicationTab/Tab";
import * as microsoftTeams from "@microsoft/teams-js";
import "./App.css";
import TabConfig from "./meetingTab/configuration/ConfigTab";
import MeetingTab from "./meetingTab/MeetingTab";
import TeamsThemeHelper from './helpers/TeamsThemeHelper';
import { Component } from "react";
import ConsentPopup from "./auth/consent/ConsentPopup";
import ClosePopup from "./auth/consent/ClosePopup";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

interface State {
  theme: ThemePrepared,
  teamsTheme: themeNames
}

class App extends Component<WithTranslation, State> {

  constructor(props: WithTranslation) {
    super(props);

    console.log("%cMB Teams v1.5", 'background: #fff; color: #28A526')

    this.state = {
      theme: TeamsThemeHelper.getTheme('default'),
      teamsTheme: TeamsThemeHelper.getTeamsTheme('default')
    }

    microsoftTeams.initialize();
    microsoftTeams.registerOnThemeChangeHandler(this.updateTheme.bind(this));
    microsoftTeams.getContext(context => {
      this.updateTheme(context.theme);
      this.updateLng(context.locale);
    });
  }

  private updateLng(locale: string): void {
    this.props.i18n.changeLanguage(locale);
  }
  private updateTheme(themeString: string | undefined): void {
    this.setState({
      theme: TeamsThemeHelper.getTheme(themeString),
      teamsTheme: TeamsThemeHelper.getTeamsTheme(themeString)
    });

    // Append the theme class to the top element
    document.documentElement.className = themeString as string;
  }

  render() {
    return (
      <Provider theme={this.state.theme}>
        <Router>
          <Route exact path="/">
            <Redirect to="/tab" />
          </Route>

          <Switch>
            {/* <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/termsofuse" component={TermsOfUse} /> */}
            <Route exact path="/tab" component={Tab} />
            <Route exact path="/config" component={TabConfig} />
            <Route exact path="/prepostmeeting" component={MeetingTab} />
            <Route exact path="/auth-start" component={ConsentPopup} />
            <Route exact path="/auth-end" component={ClosePopup} />
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default withTranslation()(App);