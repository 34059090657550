import React from 'react'
import { Image } from '@fluentui/react-northstar';
import ReloadIcon from '../../shared/ReloadIcon';
import teamsError from '../../../images/Teams-Error.png';
import errorStyles from './styles/error.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
    onClick?: () => void
}

export default function NoMappedMeetingError(props: Props) {
    const { t } = useTranslation();

    return (
        <div>
            <div className={errorStyles.error}>
                <div>
                    <Image src={teamsError} />
                    <p className={errorStyles.header}>{t('could-not-find-meetingbooster-meeting-text1')}</p>
                    <p>{t('could-not-find-meetingbooster-meeting-text2')}</p>
                </div>
            </div>
            <div>
           <ReloadIcon onClick={props.onClick} />
            </div>
        </div>
    )
}
