import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import crypto from 'crypto';
import * as microsoftTeams from "@microsoft/teams-js";

/**
 * This component is used to redirect the user to the Azure authorization endpoint from a popup
 */
export default function ConsentPopup() {
    const { t } = useTranslation();
    useEffect(() => {
        microsoftTeams.getContext((context: microsoftTeams.Context) => {

            let tenant = context['tid']; //Tenant ID of the logged in user
            let client_id = process.env.REACT_APP_AZURE_CLIENT_ID; //Client ID of the Azure AD app registration ( may be from different tenant for multitenant apps)
            let queryParams: any = {
                tenant: `${tenant}`,
                client_id: `${client_id}`,
                response_type: "token",
                prompt: "consent",
                scope: "https://graph.microsoft.com/User.Read",
                redirect_uri: window.location.origin + "/auth-end",
                nonce: crypto.randomBytes(16).toString('base64')
            }

            let url = `https://login.microsoftonline.com/${tenant}/oauth2/v2.0/authorize?`;
            queryParams = new URLSearchParams(queryParams).toString();
            let authorizeEndpoint = url + queryParams;

            //Redirect to the Azure authorization endpoint. When that flow completes, the user will be directed to auth-end
            //Go to ClosePopup.js
            window.location.assign(authorizeEndpoint);

        });
    }, []);

    return (
        <div>
            <h1>{t('consent_redirecting')}</h1>
        </div>
    );
}
