import { getMeetingBoosterToken } from '../auth/meetingBoosterAuth';
import { CouldNotFindMappingError } from './meetingBoosterErrors';
import * as microsoftTeams from '@microsoft/teams-js';

export class MeetingBoosterAPI {
    private meetingBoosterUrl: string;

    constructor(meetingBoosterUrl: string) {
        this.meetingBoosterUrl = meetingBoosterUrl;
    }

    private async requestMeetingBoosterToken() {
        return getMeetingBoosterToken(this.meetingBoosterUrl);
    }

    public getMeetingBoosterUrl(): string {
        return this.meetingBoosterUrl;
    }

    public async requestMeetingBoosterApi(endpoint: string, method: string = "GET", body: any = "") {
        let meetingBoosterToken = await this.requestMeetingBoosterToken();
        const url = this.meetingBoosterUrl + "/" + endpoint;

        const fetchOptions: RequestInit = {
            method: method,
            headers: new Headers({
                'Authorization': `Bearer ${meetingBoosterToken.access_token}`,
                'Content-Type': 'application/json'
            }),
            mode: "cors"
        };

        if (method === "POST") fetchOptions.body = JSON.stringify(body);

        let fetchRequest = await fetch(url, fetchOptions);
        return fetchRequest;
    }

    public async getMeetingBoosterMe() {
        let meetingBoosterResponse = await this.requestMeetingBoosterApi(`api/internal/internalapi/me`);
        if (meetingBoosterResponse.ok) {
            return meetingBoosterResponse.text();
        } else {
            throw new Error("Could not connect");
        }
    }

    public async getMeetingBoosterMeetingId(teamsMeetingId: string) {
        let meetingBoosterResponse = await this.requestMeetingBoosterApi(`api/internal/internalapi/mapping?meetingId=${teamsMeetingId}`);
        if (meetingBoosterResponse.ok) {
            let meetingBoosterMeeting: any[] = await meetingBoosterResponse.json();
            if (meetingBoosterMeeting.length === 0) {
                throw new CouldNotFindMappingError();
            }
            return meetingBoosterMeeting[0] as Promise<MeetingMappingDTO>;
        } else {
            throw new Error("Could not connect");
        }
    }

    public async getMeetingBoosterMeetingIdFromGuid(guid: string) {
        let meetingBoosterResponse = await this.requestMeetingBoosterApi(`api/internal/internalapi/GetIdFromGuid?guid=${guid}`, "POST");
        if (meetingBoosterResponse.ok) {
            return meetingBoosterResponse.json() as Promise<string>;
        } else {
            throw new Error();
        }
    }

    public async getMeetingBoosterMeetings(filter: any[], settings: TableSettings) {
        console.log(settings);
        console.log(filter);
        let body = {
            filters: filter,
            settings: settings
        }
        let meetingBoosterResponse = await this.requestMeetingBoosterApi("api/internal/internalapi/Meetings", "POST", body);
        if (meetingBoosterResponse.ok) {
            return meetingBoosterResponse.json() as Promise<MeetingResultDTO>;
        } else {
            throw new Error();
        }
    }

    public async getMeetingBoosterMeeting(meetingBoosterMeetingId: string) {
        let meetingBoosterResponse = await this.requestMeetingBoosterApi(`api/v1/meetings/get?id=${meetingBoosterMeetingId}`);
        if (meetingBoosterResponse.ok) {
            return meetingBoosterResponse.json() as Promise<MeetingDTO>;
        } else {
            throw new Error();
        }
    }

    public async getMeetingBoosterMeetingRecurrenceInstance(recurrenceId: number, startDateTicks: number) {
        let meetingBoosterResponse = await this.requestMeetingBoosterApi(`api/internal/internalapi/RecurringMeetingInstance?recurrenceId=${recurrenceId}&startDateTicks=${startDateTicks}`);
        if (meetingBoosterResponse.ok) {
            return meetingBoosterResponse.json() as Promise<MeetingDTO>;
        } else {
            throw new Error();
        }
    }

    public async getMeetingBoosterSingleUseURLToken(meetingBoosterMeetingId: string) {
        let meetingBoosterResponse = await this.requestMeetingBoosterApi(`api/internal/internalapi/SingleUseURLToken?meetingid=${meetingBoosterMeetingId}`);// as Promise<string>;
        if (meetingBoosterResponse.ok) {
            return meetingBoosterResponse.text() as Promise<string>;
        } else {
            throw new Error();
        }
    }

    public async getMeetingBoosterMeetingMinutes(meetingBoosterMeetingId: string) {
        let meetingBoosterResponse = await this.requestMeetingBoosterApi(`api/internal/internalapi/minutes?meetingid=${meetingBoosterMeetingId}`);
        if (meetingBoosterResponse.ok) {
            return meetingBoosterResponse.text() as Promise<string>;
        } else {
            throw new Error();
        }
    }

    public async getMeetingBoosterMeetingStatus(meetingBoosterMeetingId: string) {
        let meetingBoosterResponse = await this.requestMeetingBoosterApi(`api/internal/internalapi/meetingstatus?meetingid=${meetingBoosterMeetingId}`);
        if (meetingBoosterResponse.ok) {
            return meetingBoosterResponse.json() as Promise<MeetingStatusDTO>;
        } else {
            throw new Error();
        }
    }

    public async getMeetingBoosterMeetingTopics(meetingBoosterMeetingId: string) {
        let meetingBoosterResponse = await this.requestMeetingBoosterApi(`api/internal/internalapi/agendatopics?meetingid=${meetingBoosterMeetingId}`);
        if (meetingBoosterResponse.ok) {
            return meetingBoosterResponse.json() as Promise<TopicDTO[]>;
        } else {
            throw new Error();
        }
    }

    public openInMeetingBooster(redirectUrl: string, onDone: () => void = () => {}) {
        microsoftTeams.getContext(async context => {
            let mbToken = await getMeetingBoosterToken(this.meetingBoosterUrl);
    
            try {
                let singleUseLoginToken = await fetch(`${this.meetingBoosterUrl}/Access/RequestLoginToken`, {
                    method: "POST",
                    body: JSON.stringify({ email: context.userPrincipalName, password: "" }),
                    headers: {
                        Authorization: `Bearer ${mbToken.access_token}`
                    }
                }).then(res => res.text());
    
                let encodedUrl = encodeURI(`${this.meetingBoosterUrl}/${redirectUrl}`);
                let returnUrl = `returnUrl=${encodedUrl}`;
                singleUseLoginToken = singleUseLoginToken + "&" + returnUrl;
    
                let loginUrl = `${this.meetingBoosterUrl}/Access/LoginWithToken?${singleUseLoginToken}`;
                window.open(loginUrl, "_blank");
                onDone();
            } catch (error) {
                console.log(error);
            }
        });
    }

};