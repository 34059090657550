import { ThemePrepared, teamsTheme, teamsDarkTheme, teamsHighContrastTheme } from '@fluentui/react-northstar';
import { themeNames } from '@fluentui/react-teams';

const TeamsThemes = {
    contrast: "contrast",
    dark: "dark",
    default: "default"
};

export default class TeamsThemeHelper {
    public static getTheme(themeStr: string | undefined): ThemePrepared {
        themeStr = themeStr || '';

        switch (themeStr) {
            case TeamsThemes.dark:
                return teamsDarkTheme;
            case TeamsThemes.contrast:
                return teamsHighContrastTheme;
            case TeamsThemes.default:
            default:
                return teamsTheme;
        }
    }

    public static getTeamsTheme(themeStr: string | undefined): themeNames {
        themeStr = themeStr || '';

        switch (themeStr) {
            case TeamsThemes.dark:
                return themeNames.Dark;
            case TeamsThemes.contrast:
                return themeNames.HighContrast;
            case TeamsThemes.default:
            default:
                return themeNames.Default;
        }
    }
}