import { Component, useEffect, useState } from 'react'
import { MeetingBoosterAPI } from '../api/meetingBoosterAPI';
import ReloadIcon from '../shared/ReloadIcon';
import { AttendeeIcon, CalendarIcon, DownloadIcon, Embed, OpenOutsideIcon, PresenterIcon, ShiftActivityIcon, Skeleton } from '@fluentui/react-northstar';
import moment from 'moment';
import styles from './styles/meetingDetails.module.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface AppProps {
    meeting: MeetingDTO,
    meetingBoosterAPI: MeetingBoosterAPI
}
interface AppState {
    meeting: MeetingDTO,
    meetingStatus: MeetingStatusDTO | undefined,
    singleUseToken: string | undefined,
    userId: string,
    meetingId: string
}

export default class MeetingDetails extends Component<AppProps, AppState> {

    constructor(props: AppProps) {
        super(props);
        this.state = {
            meeting: props.meeting,
            singleUseToken: undefined,
            meetingStatus: undefined,
            userId: "",
            meetingId: ""
        }
    }

    componentDidMount() {
        this.init();
    }

    private async init() {
        this.getInfo().then((isRecurring) => {
            if (!isRecurring)
                this.getSingleUseToken()
        });
    }

    private async getInfo() {
        try {
            let meetingBoosterUserId = await this.props.meetingBoosterAPI.getMeetingBoosterMe();
            this.setState({ userId: meetingBoosterUserId });

            if (this.state.meeting.IsRecurring && !this.state.meeting.RecurrenceHasBeenInstantiated) {
                this.setState({ singleUseToken: "" });

                return true;

            } else {
                let status = await this.props.meetingBoosterAPI.getMeetingBoosterMeetingStatus(this.state.meeting.Id);
                let id = await this.props.meetingBoosterAPI.getMeetingBoosterMeetingIdFromGuid(this.state.meeting.Id);
                this.setState({ meetingStatus: status, meetingId: id });

                return false;
            }
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    private async getSingleUseToken() {
        try {
            let singleUseToken = await this.props.meetingBoosterAPI.getMeetingBoosterSingleUseURLToken(this.state.meeting.Id);
            this.setState({ singleUseToken: singleUseToken });
        } catch (error) {
            console.log(error);
        }
    }

    private async updateMeetingAndReload() {
        if (this.state.meeting.IsRecurring && !this.state.meeting.RecurrenceHasBeenInstantiated) {
            let updatedMeeting = await this.props.meetingBoosterAPI.getMeetingBoosterMeetingRecurrenceInstance(this.state.meeting.RecurrenceId as number, this.state.meeting.RecurrenceStartTicks as number);
            if (updatedMeeting !== null) {
                this.setState({ meeting: updatedMeeting, singleUseToken: undefined }, () => this.init());
            }
        } else {
            let updatedMeeting = await this.props.meetingBoosterAPI.getMeetingBoosterMeeting(this.state.meeting.Id);
            this.setState({ meeting: updatedMeeting, singleUseToken: undefined }, () => this.init());
        }
    }

    render() {
        let content = <MeetingSkeletonSetup />
        if (this.state.singleUseToken !== undefined) {
            content = <MeetingSetup userId={this.state.userId}
                meetingHasEnded={this.state.meetingStatus?.HasBeenEnded}
                singleUseToken={this.state.singleUseToken as string}
                meeting={this.state.meeting}
                meetingId={this.state.meetingId}
                api={this.props.meetingBoosterAPI}
                reloadMethod={() => this.updateMeetingAndReload()} />
        }

        return content;
    }
}

function MeetingSkeletonSetup(props: any) {

    return (
        <Skeleton animation="wave">
            <div className={styles["metadata-wrapper"]}>
                <Skeleton.Line width="300px" height="2.5em" />
                <Skeleton.Line width="300px" height="2em" />
            </div>
            <div className={styles['iframe-wrapper']}>
                <div className={styles['iframe-header']}>
                    <Skeleton.Line width="400px" height="2.5em" />
                </div>
                {/* <div className={styles["showwide"]}>
                <Skeleton.Line width="400px" height="800px" />
                </div> */}
            </div>
        </Skeleton>
    );
}

function MeetingSetup(props: any) {
    const startDateObject = moment(props.meeting.Start);
    const endDateObject = moment(props.meeting.End);
    let describer = "";
    let attendees = <></>;
    let meetingDetails = <></>;
    let activateDownloadButton = true;
    const [iframeLoaded, setIframeLoaded] = useState(false);

    // To detect that the iframe has been loaded so its height can be adjustet
    useEffect(() => {
        if (iframeLoaded) {
            let shouldResize = !props.meeting.IsRecurring || props.meeting.RecurrenceHasBeenInstantiated;
            resizeIFrame(shouldResize);
            window.onresize = () => resizeIFrame(shouldResize);
        }
    }, [iframeLoaded])

    const { t } = useTranslation();

    // Set any variables here which depends on the meeting status
    if (props.meetingHasEnded) {
        describer = t('minutes-text');
        if (props.meeting.Invitations.length !== 0) {
            attendees = <><AttendeeIcon outline /> {props.meeting.Invitations.map((item: any) => item.User.Name).join(", ")}</>
        }
    } else {
        describer = t('agenda-text')
    }
if (window.innerWidth < 750) {
    meetingDetails = <div></div>
} else if (!props.meeting.IsRecurring || props.meeting.RecurrenceHasBeenInstantiated) {
        let offset = new Date(props.meeting.Start).getTimezoneOffset();
        
        meetingDetails = <Embed active hidden={!iframeLoaded}
            iframe={{
                src: props.api.getMeetingBoosterUrl() + `/Minutes/AgendaMinutesForTeams?id=${props.meeting.Id}&offset=${offset}&userId=${props.userId}&token=${props.singleUseToken}`,
                height: '800px',
                width: '900px',
                frameBorder: 0,
                id: 'embeddedIframe'
            }} onLoad={() => setIframeLoaded(true)} />;
    } else {
        activateDownloadButton = false;
        meetingDetails = <div>No agenda present for this meeting.</div>

        if (!iframeLoaded)
            setIframeLoaded(true);
    }

    return (
        <>
            <div className={styles["metadata-wrapper"]}>
                <div className={styles["meeting_title"]}>{props.meeting.Title}</div>
                <CalendarIcon outline style={{ verticalAlign: "sub" }} /> {startDateObject.format("MMMM")} {startDateObject.format("Do")}, {startDateObject.format("YYYY")}
                <span style={{ marginLeft: "20px" }}><ShiftActivityIcon outline style={{ verticalAlign: "sub" }} /> {startDateObject.format("HH")}:{startDateObject.format("mm")} - {endDateObject.format("HH")}:{endDateObject.format("mm")}</span>
                <span style={{ marginLeft: "20px" }}><PresenterIcon outline style={{ verticalAlign: "sub" }} /> {props.meeting.Host.Name}</span>
                <div className={styles["attendeeslist"]} style={{ marginTop: "5px" }}>{attendees}</div>
            </div>
                <div className={styles['iframe-wrapper']}>
                    <div className={styles['iframe-header']}>
                        <span style={{ fontSize: "1.5em", marginRight: "20px", fontWeight: 600 }}>{describer}</span>
                        <span className={activateDownloadButton ? "link" : ""} onClick={() => downloadDetails(props.api, props.meetingId, props.meeting, activateDownloadButton)} style={{ marginRight: "20px" }}><DownloadIcon size="small" /> {t('download')}</span>
                        <span className="link" onClick={() => openInMeetingBooster(props.api, props.meeting)}><OpenOutsideIcon outline size="small" /> {t('open-in-meetingbooster')}</span> <span id="open-in-mb-loader" hidden>Logging into MB</span>
                    </div>
                    <div className={styles["showwide"]}>
                        <Skeleton animation="wave">
                        <Skeleton.Line width="900px" height="800px" hidden={iframeLoaded} />
                            {meetingDetails}
                        </Skeleton>        
                    </div>
                </div>
            <div>
                <ReloadIcon onClick={props.reloadMethod} />
            </div>
            {/* <div  className={styles["info"]}>1</div> */}
           {/* <div>
            {window.innerWidth}
           </div> */}
        </>
    );
}

function resizeIFrame(shouldResize: boolean) {
    if (shouldResize) {
        let iframe = document.querySelector("#embeddedIframe") as HTMLIFrameElement;
        let newHeight = window.innerHeight - iframe.getBoundingClientRect().top - window.scrollY - 20;
        iframe.height = newHeight + "";
    }
}

function downloadDetails(meetingBoosterApi: MeetingBoosterAPI, meetingId: any, meeting: any, recurrenceHasBeenInstantiated: boolean) {
    if (recurrenceHasBeenInstantiated) {
        let offset = new Date(meeting.Start).getTimezoneOffset();
        let newYear = new Date(2000, 1, 1, 12);
		let newYearOffset = newYear.getTimezoneOffset();
        let redirectUrl = `Minutes/DetailsPreMeetingPdfDownload?id=${meetingId}!offset=${offset}!newYearOffset=${newYearOffset}`;
        meetingBoosterApi.openInMeetingBooster(redirectUrl);
    }
}

function openInMeetingBooster(meetingBoosterApi: MeetingBoosterAPI, meeting: MeetingDTO) {
    let redirectUrl;

    if (!meeting.IsRecurring || meeting.RecurrenceHasBeenInstantiated) {
        redirectUrl = `Meetings/ViewMeeting/${meeting.Id}`;
    } else {
        redirectUrl = `Meetings/CreateRecurringInstanceAndEdit/${meeting.RecurrenceId}?StartDate=${meeting.RecurrenceStartTicks}`;
    }

    document.getElementById("open-in-mb-loader")!.hidden = false;
    meetingBoosterApi.openInMeetingBooster(redirectUrl, () => {
        document.getElementById("open-in-mb-loader")!.hidden = true;
    });
}