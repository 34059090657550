import React from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import Meetings from './meetings/Meetings';
import MeetingBooster from '../general/MeetingBooster';
import { Loader } from "@fluentui/react-northstar";
import { getAzureAccessToken,/*, silentAuth*/ IsTeams } from '../auth/teamsAuth';

interface AppState {
  meetingBoosterUrl: string
}

export default class Tab extends React.Component<{}, AppState> {
  constructor(props: any) {
    super(props);
    this.state = {
      meetingBoosterUrl: ""
    };

    this.urlConfirmed = this.urlConfirmed.bind(this);

    microsoftTeams.appInitialization.notifySuccess();
  }

  componentDidMount() {
    IsTeams((yes: boolean)=> {
      if (!yes) {
        document.location.href = "https://www.meetingbooster.com";
      }
    })
  }

  private urlConfirmed(url: string, verified: boolean) {

    if (verified) {
      microsoftTeams.getContext(() => {
        this.setState({ meetingBoosterUrl: url });
      });
    }
  }

  render() {

    // if (!IsTeams()) {
    //   return (
    //     <div>Not Teams</div>
    //   )
    // }

    const ready = this.state.meetingBoosterUrl !== "";
    let content = <Loader />

    if (ready) {
      content = <Meetings meetingBoosterUrl={this.state.meetingBoosterUrl} />;
    }

    return (
      <div>
        <MeetingBooster callback={this.urlConfirmed}>
          {content}
        </MeetingBooster>
      </div>

    );
  }
}
