import * as microsoftTeams from "@microsoft/teams-js";
import { ConsentNotGivenError, CustomError } from "../api/meetingBoosterErrors";
import * as msal from "@azure/msal-browser";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { sendMessageToParent } from "@microsoft/teams-js";
import { useTranslation } from "react-i18next";
//import { Console } from "console";

// The issued MeetingBooster token is saved in memory
// This ensures that it is forgotten, whenever the current user signs out
// If it were to be saved in localstorage or similar, it would be accessible for other users if they logged into the same machine.
let issuedAzureToken: any;

// This can be used instead of the "on-behalf" auth flow, and doing so eliminating the need for the Auth server
// However, this approach can not be approved by an admin, and so each user must consent in the app.
// Additionally, the consent is only valid for the duration of the login session, standard 90 days rolling window, but is set by each tenant
// Therefore i opted to use the "on-behalf" flow, as it gives the end-users a better experience.
// I'll leave the code in, incase the other approach is deemed better in the future.
// export async function silentAuth() {
//     return new Promise<string>((resolve, reject) => {


//         const msalConfig = {
//             auth: {
//                 clientId: '983feaa2-c3f9-423d-8847-4b2773640709',
//                 redirectUri: "https://localhost:3000/blank"
//             }
//         };

//         const msalInstance = new msal.PublicClientApplication(msalConfig);
//         microsoftTeams.getContext(async context => {
//             var loginRequest = {
//                 scopes: ["user.read"],
//                 loginHint: context.loginHint
//             };
//             try {
//                 const loginResponse = await msalInstance.ssoSilent(loginRequest);
//                 console.log(loginResponse);
//                 resolve((loginResponse as msal.AuthenticationResult).accessToken);
//             } catch (err) {
//                 console.log(err);
//                 if (err instanceof InteractionRequiredAuthError) {
//                     await msalInstance.loginPopup(loginRequest).then(response => {
//                         console.log(response);
//                         resolve((response as msal.AuthenticationResult).accessToken);
//                     }).catch(error => {
//                         // handle error

//                         console.log(error);
//                         reject();
//                     });

//                 } else {
//                     // handle error
//                     console.log(err);
//                 }
//             }
//         })
//     });
// }

export async function getAzureAccessToken() {
    const azureAccessToken = issuedAzureToken;
    
    // Verify that it has been set and not expired
    const epochNow = Math.floor(Date.now() / 1000);
    if (azureAccessToken !== undefined) {
        if (azureAccessToken.expires_epoch > epochNow) {
            return new Promise(resolve => resolve(azureAccessToken.access_token));
        }
    }

    let accessTokenObject: any = await renewAccessToken();
    return accessTokenObject.access_token;
}

function ExtractEmailFromUserPrincipalName(userPrincipal: string | undefined): string | null
{
    if (!userPrincipal) return null;
    
    var mail = userPrincipal;
    if (mail.indexOf("#EXT#") > -1) {
        mail = mail.substring(0, mail.indexOf("#EXT#"));
        if (mail.split("_").length != 2)
            return null;
        mail = mail.replace('_', '@');
    }

    var reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    var m = mail.match(reg);

    return (m && m.length > 0) ? m[0] : null;
}

export function IsTeams(retFunc: (yes: boolean)=>void): void
{
    var to = setTimeout(() => {
        retFunc(false);
    }, 500);
    try {
        microsoftTeams.getContext((a)=> {
            clearTimeout(to);
            retFunc(a != null && a != undefined);
        });
        //return window.parent != window;
        //microsoftTeams.generateGUID();
        retFunc(true);
        } catch (eee) {
            retFunc(false);
        }
}

export async function Teams_GetUserEmail(): Promise<string | null>
{
    //console.log(ExtractEmailFromUserPrincipalName("thomas@toftborg.dk"));
    //console.log(ExtractEmailFromUserPrincipalName("thomas_toftborg.dk#EXT#sdfsdf"));
    return new Promise((resolve, reject) => {
        try {
        microsoftTeams.getContext((context) => {  
            var email = ExtractEmailFromUserPrincipalName(context.userPrincipalName);
            if (email == null) {
                email = ExtractEmailFromUserPrincipalName(context.loginHint);
            }
            resolve(email);
        });
    } catch (E) {
        resolve(null);
    }
    });
}

export function getClientSideToken() {
    return new Promise((resolve, reject) => {
        microsoftTeams.authentication.getAuthToken({
            successCallback: (result) => {
                resolve(result);
            },
            failureCallback: function (error) {
                console.log(error);
                reject("Error getting token: " + error);
            }
        });

    });

}

export function getServerSideToken(clientSideToken: string) {

    // Look for a stored token first which is not expired
    const ssoToken = issuedAzureToken;
    const epochNow = Math.floor(Date.now() / 1000);
    if (ssoToken !== undefined) {
        if (ssoToken.expires_epoch > epochNow) {
            return new Promise(resolve => resolve(ssoToken));
        }
    }

    return new Promise((resolve, reject) => {
        microsoftTeams.getContext((context) => {
            let params = {
                ssoToken: clientSideToken
            };
            const authenticationServer = process.env.REACT_APP_AUTHENTICATION_SERVER;

            //console.log("AuthenticationServer Url: "+authenticationServer);

            fetch(`${authenticationServer}/auth/getGraphAccessToken?` + new URLSearchParams(params).toString(), {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json'
                },
                mode: 'cors',
                cache: 'default'
            })
                .then((response) => {
                    return response.json();
                })
                .then(async (responseJson) => {
                    if (responseJson.error) {
                        let error = responseJson.error;
                        console.error(responseJson.error);
                        console.error(responseJson.error_description);
                        if (error === "consent_required") {
                            let tokenRaw = await requestConsent();
                            let token = JSON.parse(tokenRaw as string);
                            saveSSOToken(responseJson);
                            resolve(token);
                        } else {
                            throw error;
                        }
                    } else {
                        saveSSOToken(responseJson);
                        resolve(responseJson);
                    }
                }).catch(error => {
                    console.log(error);
                    switch (error) {
                        case "consent_not_given":
                            reject(new ConsentNotGivenError());
                            break;
                        case "CancelledByUser":
                            reject(new CustomError("Consent request was cancelled by the user."));
                            break;
                        default:
                            reject(new Error("Not a valid MeetingBooster URL. Please try again."));
                    }
                });
        });
    });
};

//export withTranslation()(getServerSideToken);

function saveSSOToken(ssoToken: any) {
    const expiresIn = parseInt(ssoToken.expires_in);
    const epochNow = Math.floor(Date.now() / 1000);
    const tokenExpireEpoch = epochNow + expiresIn;
    ssoToken.expires_epoch = tokenExpireEpoch;
    issuedAzureToken = ssoToken;
}

export function useServerSideToken(url: string = "https://graph.microsoft.com/v1.0/me/", data: any) {
    return fetch(url,
        {
            method: 'GET',
            headers: {
                "accept": "application/json",
                "authorization": "Bearer " + data.access_token
            },
            mode: 'cors',
            cache: 'default'
        })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {

            }
        });

}

function requestConsent() {
    return new Promise((resolve, reject) => {
        microsoftTeams.authentication.authenticate({
            url: window.location.origin + "/auth-start",
            width: 600,
            height: 535,
            successCallback: (result) => {
                console.log(result);
                resolve(result);
            },
            failureCallback: (reason) => {
                reject(reason);
            }
        });
    });
}

export async function renewAccessToken() {
    return getClientSideToken()
        .then((clientSideToken) => {
            return getServerSideToken(clientSideToken as string);
        })
}