import { Button, Card, ChevronStartIcon, Flex, Text } from '@fluentui/react-northstar'
import React from 'react'
import styles from './styles/topic.module.scss';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
interface Props {
    goBack: () => void,
    topic: TopicDTO
}

export default function TopicDetails(props: Props) {
    const {t} = useTranslation();

    let startTime = moment(props.topic.StartTimeISO);
    let endTime = moment(props.topic.StartTimeISO).add(props.topic.Duration, "seconds")

    let time = "";

    if (props.topic.Duration !== 0) {
        time = `${startTime.format("HH:mm")} - ${endTime.format("HH:mm")}`;
    }

    return (
        <div>
            <Button icon={<ChevronStartIcon size={'larger'} />} iconOnly text onClick={() => props.goBack()} />
            <span style={{ fontSize: "1.2em", marginLeft: 5 }}>{t('topic_headeragenda')}</span><br />
            <Card fluid variables={{backgroundColorHover: "#292828", margin: "1em 0 0 0"}}>
                <Card.Body>
                    <Flex column space="between" gap="gap.small">
                        <Text content={`${props.topic.Numbering} ${props.topic.Title}`} weight="semibold" />
                        <Flex space="between">
                            <Text content={time} className={styles['topic-metadata']} />
                            <Text content={props.topic.PresenterNames} className={styles['topic-metadata']} />
                        </Flex>
                        <div className={styles['topic-description']} dangerouslySetInnerHTML={{ __html: props.topic.Description }} />
                    </Flex>
                </Card.Body>
            </Card>
        </div>
    )
}
