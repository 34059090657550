import { Component } from 'react'
import MeetingBooster from '../../general/MeetingBooster';
import Agenda from './Agenda';
import * as microsoftTeams from "@microsoft/teams-js";
import 'signalr';
import { MeetingBoosterAPI } from '../../api/meetingBoosterAPI';
import { CouldNotFindMappingError } from '../../api/meetingBoosterErrors';
import { TeamsContext } from '../../general/MeetingBooster';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Button } from '@fluentui/react-northstar';

interface AppState {
    searchingForUrl: boolean,
    meetingBoosterUrl: string,
    meeting: MeetingDTO | null,
    meetingBoosterMeetingStarted: boolean,
    meetingBoosterMeetingEnded: boolean,
    meetingBoosterMeetingId: number,
    meetingBoosterMeetingGuid: string,
    meetingBoosterUserId: string,
    userEmail: string,
    agenda: AgendaDTO | null,
    signalrConnection: SignalR.Hub.Connection | null,
    signalrConnectionEstablished: boolean,
    selectedTopic: number,
    userCanStartMeeting: boolean,
    error: boolean,
    errorMessage: string
};

class InMeeting extends Component<WithTranslation, AppState> {

    private meetingBoosterAPI!: MeetingBoosterAPI;

    constructor(props: any) {
        super(props);
        this.state = {
            searchingForUrl: true,
            meetingBoosterUrl: "",
            meetingBoosterMeetingStarted: false,
            meetingBoosterMeetingEnded: false,
            meetingBoosterMeetingId: -1,
            meetingBoosterMeetingGuid: "",
            meetingBoosterUserId: "",
            userEmail: "",
            meeting: null,
            agenda: null,
            signalrConnection: null,
            signalrConnectionEstablished: false,
            selectedTopic: -1,
            userCanStartMeeting: false,
            error: false,
            errorMessage: ""
        };

        this.urlConfirmed = this.urlConfirmed.bind(this);
        this.renderThumb = this.renderThumb.bind(this);
    }

    componentWillUnmount() {
        this.stopSignalrConnection();
    }

    // Start point for this class. Will be called by the Url verification component when the url is verified
    private urlConfirmed(url: string, verified: boolean) {
        if (verified) {
            this.setState({ meetingBoosterUrl: url });
            this.meetingBoosterAPI = new MeetingBoosterAPI(url);

            microsoftTeams.getContext(async (context: microsoftTeams.Context) => {
                try {
                    let meetingBoosterMeetingId = await this.meetingBoosterAPI.getMeetingBoosterMeetingId(context.meetingId as string);
                    let meetingBoosterUserId = await this.meetingBoosterAPI.getMeetingBoosterMe();
                    let meeting = await this.meetingBoosterAPI.getMeetingBoosterMeeting(meetingBoosterMeetingId.MeetingGuid);

                    this.setState({ meetingBoosterMeetingGuid: meetingBoosterMeetingId.MeetingGuid, 
                        meetingBoosterMeetingId: meetingBoosterMeetingId.MeetingId, 
                        meeting: meeting, 
                        userEmail: context.userPrincipalName as string,
                        meetingBoosterUserId: meetingBoosterUserId
                    });
                    let meetingBoosterMeetingStatus = await this.meetingBoosterAPI.getMeetingBoosterMeetingStatus(this.state.meetingBoosterMeetingGuid);
                    this.setMeetingStatus(meetingBoosterMeetingStatus);
                    // Only fetch the agenda if the meeting has been started
                    // As it might not be up to date, when the meeting is started
                    // So it would need to be fetched again anyway
                    if (this.state.meetingBoosterMeetingStarted) {
                        this.getMeetingBoosterAgenda();
                    }
                    this.startSignalrConnection(meetingBoosterMeetingId.MeetingId, meetingBoosterUserId);
                } catch (error) {
                    if (error instanceof CouldNotFindMappingError) {
                        const errorMessage = this.props.t('inmeeting_errornotfound');
                        this.setState({ error: true, errorMessage: errorMessage })
                    } else {
                        console.log(error);
                    }
                }
            });
        }
    }

    private stopSignalrConnection() {
        this.state.signalrConnection?.stop(true, true);
    }

    private startSignalrConnection(meetingId: number, userId: string) {
        const hostname = new URL(this.state.meetingBoosterUrl).hostname;
        let connection = $.hubConnection(this.state.meetingBoosterUrl);
        let proxy = connection.createHubProxy("notifyHub")
        proxy.on("ProcessData", (data) => {
            if (data !== null)
                this.processMeetingBoosterData(data);
        });
        connection.qs = { "MeetingId": meetingId, "UserId": userId, "Domain": hostname, "Teams": true };
        this.setState({ signalrConnection: connection });

        connection.start().done(() => {
            this.signalrConnectionEstablished();
        }).fail((e) => console.log(e));
    }

    private signalrConnectionEstablished() {
        this.setState({ signalrConnectionEstablished: true, userCanStartMeeting: this.canUserStartMeeting() });
    }

    private setMeetingStatus(meetingStatus: any) {
        this.setState({
            meetingBoosterMeetingStarted: meetingStatus.IsRunning,
            meetingBoosterMeetingEnded: meetingStatus.HasBeenEnded,
            selectedTopic: meetingStatus.CurrentTopic
        });
    }

    private async getMeetingBoosterAgenda() {
        try {
            let meetingBoosterMeetingTopics = await this.meetingBoosterAPI.getMeetingBoosterMeetingTopics(this.state.meetingBoosterMeetingGuid);

            const agenda: AgendaDTO = { topics: meetingBoosterMeetingTopics };
            this.setState({ agenda: agenda });

        } catch (error) {
            console.log(error);
        }
    }

    private async processMeetingBoosterData(data: string) {
        const parsedData = JSON.parse(data) as MeetingBoosterSignalRData;
        switch (parsedData.lastEvent) {
            case 0: // Topic change
                this.setState({ selectedTopic: parsedData.state.TopicState.CurrentAgendaTopic });
                break;
            case 3: // Meeting started
                await this.getMeetingBoosterAgenda();
                // Set the first topic as current
                let firstTopicId = this.state.agenda?.topics[0].Id as number;
                this.setState({ meetingBoosterMeetingStarted: true, selectedTopic: firstTopicId });
                const hostname = new URL(this.state.meetingBoosterUrl).hostname;
                let groupId = hostname + this.state.meetingBoosterMeetingId;
                this.meetingBoosterAPI.requestMeetingBoosterApi(`RunMeetingTeams/Join?id=${this.state.meetingBoosterMeetingId}&userId=${this.state.meetingBoosterUserId}&groupId=${groupId}`);

                break;
            case 4: // Agenda changed
                this.getMeetingBoosterAgenda();
                break;
            case 5: // Meeting ended
                this.setState({ meetingBoosterMeetingEnded: true });
                break;
            default:
                break;
        }
    }

    private renderThumb({ style, ...props }: any) {
        const thumbStyle = {
            backgroundColor: `#797979`,
            borderRadius: 3,
            cursor: 'pointer'
        };
        return (
            <div
                style={{ ...style, ...thumbStyle }}
                {...props} />
        );
    }

    private canUserStartMeeting(): boolean {
        if (this.state.meeting?.Host.Email.toLowerCase() === this.state.userEmail.toLowerCase()) {
            return true;
        } else {
            let index = this.state.meeting?.Invitations.findIndex(x => x.User.Email.toLowerCase() === this.state.userEmail.toLowerCase())
            if (index !== -1 && index !== undefined) {
                return this.state.meeting?.Invitations[index].IsCoHost as boolean;
            }
        }
        return false;
    }

    render() {
        let content;
        let redirectUrl = `RunMeeting/New/${this.state.meetingBoosterMeetingId}`;
        content = <>{this.props.t('inmeeting_loading')}</>;
        if (!this.state.signalrConnectionEstablished) {
            content = <>{this.props.t('inmeeting_connecting')}</>;
        }
        if (this.state.signalrConnectionEstablished && !this.state.meetingBoosterMeetingStarted) {
            if (this.state.userCanStartMeeting) {
                content = <div style={{ textAlign: "center" }}>
                    {this.props.t('inmeeting_waiting')}<br />
                    <Button style={{ marginTop: '20px' }} onClick={() => this.meetingBoosterAPI.openInMeetingBooster(redirectUrl)}>Start MeetingBooster meeting</Button>
                </div>;
            } else {
                content = <div style={{ textAlign: "center" }}>{this.props.t('inmeeting_waiting')}</div>;
            }
        }
        if (this.state.signalrConnectionEstablished && this.state.meetingBoosterMeetingStarted && this.state.agenda === null) {
            content = <>{this.props.t('inmeeting_loadingagenda')}</>;
        }
        if (this.state.signalrConnectionEstablished && this.state.meetingBoosterMeetingStarted && this.state.agenda !== null) {
            content = <Agenda agenda={this.state.agenda} selectedTopic={this.state.selectedTopic} />;
        }
        if (this.state.meetingBoosterMeetingEnded) {
            content = <>{this.props.t('inmeeting_ended')}</>;
        }
        if (this.state.error) {
            content = <>{this.state.errorMessage}</>;
        }


        return (
            <div style={{ backgroundColor: '#1f1f1f', paddingLeft: "0px", paddingRight: "0px" }}>
                {/* <Scrollbars autoHide renderThumbVertical={this.renderThumb}> */}
                <MeetingBooster verifyContext={TeamsContext.InMeeting} callback={this.urlConfirmed}>
                    {content}
                </MeetingBooster>
                {/* </Scrollbars> */}
            </div>
        )
    }
}

export default withTranslation()(InMeeting);