import React, { useEffect, useState } from 'react'
import { Loader, Flex, Text, Table, Input } from '@fluentui/react-northstar';
import { ChevronEndIcon, ChevronStartIcon, FilterIcon } from '@fluentui/react-icons-northstar';
import { useTranslation } from 'react-i18next';
import commonStyles from '../../shared/styles/common.module.scss';
import styles from './styles/meetingsList.module.scss';
import './styles/global-style.scss';
import moment from 'moment';
import MeetingsFilter from './MeetingsFilter';
import ReactPaginate from 'react-paginate';
// import {BrowserView, MobileView} from 'react-device-detect';

interface Props {
    meetings: MeetingDTO[],
    getMeetingBoosterMeetings: (filter: any[]) => void,
    selectMeeting: (meeting: MeetingDTO) => void,
    changePage: (data: { selected: number }) => void,
    currentPage: number,
    pageCount: number | undefined,
    changePastFilter: (newFilter: any) => void,
    loadingMeetings: boolean
}

export default function MeetingsList(props: Props) {
    const [filterOpen, setFilterOpen] = useState(false);
    const [viewUpcoming, setViewUpcoming] = useState(true);
    const [searchText, setSearchText] = useState("");
    const [t] = useTranslation();

    useEffect(() => {
        props.getMeetingBoosterMeetings([]);
    }, [])

    function changeFilterSelection(filters: any[]) {
        props.getMeetingBoosterMeetings(filters);
    }

    function changeSearchFilter(value: any) {
        setSearchText(value);
    }

    function onEnter(e: any) {
        if (e.keyCode === 13) {
            let newSearchFilter = {
                "name": "SearchFilter",
                "value": [searchText]
            }
            props.getMeetingBoosterMeetings([newSearchFilter])
        }
    }

    function changeUpcomingPastSelection(value: boolean) {
        let newPastMeetingsFilter = {
            "name": "PastMeetingsFilter",
            "value": [value]
        };

        props.changePastFilter(newPastMeetingsFilter);
        setViewUpcoming(!value);
    }

    const header = {
        key: "header",
        items: [
            {
                content: t('meetingslist_headername'),
                key: "name"
            },
            {
                content: t('meetingslist_headerorganizer'),
                key: "organizer",
                className: styles["showwide"]
            },
            {
                content: t('meetingslist_headerdate'),
                key: "date",
                className: styles["showwide"]
            },
            {
                content: t('meetingslist_headertime'),
                key: "time",
                className: styles["showwide"]
            }
        ]
    };

    let ffrows = props.meetings.map(meeting => {
        const startDateObj = moment(meeting.Start);
        const endDateObj = moment(meeting.End);
        return {
            key: meeting.Id,
            items: [
                {
                    content: <div className={styles['table-title']}>{meeting.Title}</div>,
                    truncateContent: true,
                    key: meeting.Id + "1"
                },
                {
                    content: meeting.Host.Name,
                    truncateContent: true,
                    key: meeting.Id + "2",
                    className: styles["showwide"]
                },
                {
                    content: startDateObj.format("DD/MM/YYYY"),
                    truncateContent: true,
                    key: meeting.Id + "3",
                    className: styles["showwide"]
                },
                {
                    content: startDateObj.format("HH:mm") + " - " + endDateObj.format("HH:mm"),
                    truncateContent: true,
                    key: meeting.Id + "4",
                    className: styles["showwide"]
                }
            ],
            onClick: () => props.selectMeeting(meeting)
        }
    })

    let pagination = <></>;
    if (props.pageCount !== undefined && props.pageCount > 1) {
        pagination = <ReactPaginate
            pageCount={props.pageCount}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            onPageChange={props.changePage}
            forcePage={props.currentPage}
            activeClassName={styles["pagination-active"]}
            pageClassName={styles['pagination-page']}
            breakClassName={styles['pagination-page']}
            nextLinkClassName={styles['pagination-page-next-previous']}
            previousLinkClassName={styles['pagination-page-next-previous']}
            containerClassName={styles['pagination']}
            previousLabel={<ChevronStartIcon />}
            nextLabel={<ChevronEndIcon />}
        />
    }

    return (
        <div className={commonStyles["wrapper"]}>
            <div>
                <Flex gap="gap.small">
                    <span className={`${styles['upcoming_past_links']} ${styles[viewUpcoming ? "selected" : ""]}`} onClick={() => changeUpcomingPastSelection(false)}>{t('meetingslist_upcoming')}</span>
                    <span className={`${styles['upcoming_past_links']} ${styles[viewUpcoming ? "" : "selected"]}`} onClick={() => changeUpcomingPastSelection(true)}>{t('meetingslist_past')}</span>
                </Flex>
            </div>
            {/* <BrowserView> */}
            <br />
            <div className={styles["showwide"]}>
                
                <div className={styles["header-wrapper"]}>
                    <Flex gap="gap.small" space="between" vAlign="center">

                        <Flex gap="gap.small">
                            <span className={styles["header-text"]}>{viewUpcoming ? t('meetingslist_upcomingtitle') : t('meetingslist_pasttitle')} </span>
                        </Flex>

                        <Flex gap="gap.large" vAlign="center" >
                            <Flex gap="gap.smaller" vAlign="center" style={{ cursor: "pointer" }} onClick={() => setFilterOpen(!filterOpen)}>
                                <FilterIcon />
                                <Text content={t('meetingsfilter_header')} />
                            </Flex>
                            <Input placeholder={t('common_search')} onKeyDown={(e) => onEnter(e)} onChange={(e) => changeSearchFilter((e.target as HTMLInputElement).value)} />
                        </Flex>

                    </Flex>
                </div>
            </div>
            {/* </BrowserView> */}
            <div className={styles["table-wrapper"]}>
                {props.loadingMeetings && <Loader />}
                {!props.loadingMeetings && <Table compact={false} header={header} rows={ffrows} />}
                <MeetingsFilter mfd={'aaa'} show={filterOpen} onClose={() => setFilterOpen(false)} onFilterChange={changeFilterSelection} />
            </div>
            {pagination}
        </div>
    )
}
