import { Component } from 'react'
import { Button, Input } from "@fluentui/react-northstar";
import { verifyMeetingBoosterUrl } from '../auth/meetingBoosterAuth';
import { AuthServerDidNotRespond, ConsentNotGivenError, CustomError } from '../api/meetingBoosterErrors';
import styles from './styles/confirm-meetingbooster-url.module.scss';
import { TeamsContext } from './MeetingBooster';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface AppProps extends WithTranslation {
    meetingBoosterUrl: string,
    callback: Function,
    verifyContext: TeamsContext
}
interface AppState {
    url: string
    message: string,
    verified: boolean,
    verifying: boolean
}

class ConfirmMeetingBoosterUrl extends Component<AppProps, AppState> {

    constructor(props: AppProps) {
        super(props);
        this.state = {
            url: props.meetingBoosterUrl,
            message: "",
            verified: false,
            verifying: false
        }

        this.handleChange = this.handleChange.bind(this);
        this.VerifyMeetingBoosterUrl = this.VerifyMeetingBoosterUrl.bind(this);
    }

    private async VerifyMeetingBoosterUrl() {
        this.setState({ message: "", verifying: true });
        try {
            let verified = await verifyMeetingBoosterUrl(this.state.url);
            if (verified) {
                this.setState({ verified: true, message: 'URL verified, please press "Save" to continue.' })
                localStorage.setItem("meetingBoosterUrl", this.state.url);
            } else {
                this.setState({ verified: false, message: this.props.t('url_CouldNotVerify') });
            }
            this.props.callback(this.state.verified, this.state.url);

        } catch (error: any) {
            console.error(error);
            if (error instanceof AuthServerDidNotRespond) {
                this.setState({ verified: false, message: error.message });
            } else if (error instanceof ConsentNotGivenError) {
                this.setState({ verified: false, message: error.message });
            } else if (error instanceof CustomError) {
                this.setState({ verified: false, message: error.message });
            } else {
                this.setState({ verified: false, message: error.message });
            }
        }
        this.setState({ verifying: false });
    }

    private handleChange(event: any) {
        this.setState({ url: event.target.value })


    }

    render() {
        let text;
        let buttonContent;
        if (this.props.meetingBoosterUrl !== "") {
            text = <>
                <div className={`${styles['verification-text-first']} ${styles[this.props.verifyContext]}`}>{this.props.t('url_verify1')}</div>
                <div className={`${styles['verification-text-second']} ${styles[this.props.verifyContext]}`}>{this.props.t('url_verify1')}</div>
            </>
        } else if (this.props.meetingBoosterUrl === "") {
            text = <>
                <div className={`${styles['verification-text-first']} ${styles[this.props.verifyContext]}`}>{this.props.t('url_notlocated')}</div>
                <div className={`${styles['verification-text-second']} ${styles[this.props.verifyContext]}`}>{this.props.t('url_enterurl')}</div>
            </>
        }
        buttonContent = this.state.verifying ? "" : this.props.t('buttontext_verify');

        return (
            <>
                {text}
                <Input type="text" placeholder={this.props.t("EnterMeetingBoosterUrlHere")} fluid inverted value={this.state.url} disabled={this.state.verified} onChange={this.handleChange} className={`${styles['verify-input']} ${styles[this.props.verifyContext]}`} />
                <Button primary onClick={this.VerifyMeetingBoosterUrl} disabled={this.state.verified} id={"verifyButton"} loading={this.state.verifying} content={buttonContent} />
                <br />
                {this.state.message !== "" &&
                    this.state.message
                }
                <p>&nbsp;</p>
                <p><b><Trans i18nKey="TryMeetingBooster" />
                </b></p>
                <p>
                    <Trans i18nKey="TryLink" components={{ atag: <a /> }} />
                </p>
                <p>
                <Trans i18nKey="LearnMoreLink" components={{ atag: <a /> }} />
                </p>


            </>
        )
    }
}

export default withTranslation()(ConfirmMeetingBoosterUrl);