import { Component } from 'react'
import * as microsoftTeams from "@microsoft/teams-js";
import PrePostMeetingTab from './prePostMeeting/PrePostMeetingTab';
import InMeetingTab from './inMeeting/InMeetingTab';
import React from 'react';
import { IsTeams } from '../auth/teamsAuth';

interface AppState {
    frameContext: FrameContext,
    meetingBoosterUrl: string,
    searchingForUrl: boolean
}

enum FrameContext {
    Content,
    SidePanel,
    Loading
}

export default class MeetingTab extends Component<{}, AppState> {

    constructor(props: any) {
        super(props);

        this.state = {
            frameContext: FrameContext.Loading,
            meetingBoosterUrl: "",
            searchingForUrl: true
        };
    }




    componentDidMount() {

        IsTeams((yes: boolean)=> {
            if (!yes) {
              document.location.href = "https://www.meetingbooster.com";
            }
          })

        microsoftTeams.appInitialization.notifySuccess();
        microsoftTeams.getContext(context => {
            if (context.frameContext === "content") {
                this.setState({ frameContext: FrameContext.Content });
            }

            if (context.frameContext === "sidePanel") {
                this.setState({ frameContext: FrameContext.SidePanel });
            }
        });
    }

    render() {
        let content = <></>;
        if (this.state.frameContext === FrameContext.Content) {
            content = <PrePostMeetingTab />;
        }

        if (this.state.frameContext === FrameContext.SidePanel) {
            content = <InMeetingTab />;
        }

        return content;
    }
}
