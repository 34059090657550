import { getAzureAccessToken/*, silentAuth*/ } from './teamsAuth';

// The issued MeetingBooster token is saved in memory
// This ensures that it is forgotten, whenever the current user signs out
// If it were to be saved in localstorage or similar, it would be accessible for other users if they logged into the same machine.
let issuedMBToken: any;

export async function getMeetingBoosterToken(meetingBoosterUrl: string) {
    let azureAccessToken = await getAzureAccessToken();
    //let azureAccessToken = await silentAuth();
    const mbAccessToken = issuedMBToken;
    // Verify that it has been set and not expired
    const epochNow = Math.floor(Date.now() / 1000);
    if (mbAccessToken !== undefined) {
        if (mbAccessToken.expires_epoch > epochNow) {
            return mbAccessToken;
        } else {
            return renewMeetingBoosterToken(mbAccessToken.refresh_token, meetingBoosterUrl);
        }
    }

    return authorizeInMeetingBooster(azureAccessToken, meetingBoosterUrl);
}

async function renewMeetingBoosterToken(refreshToken: any, meetingBoosterUrl: string) {
    let body = {
        grant_type: "refresh_token",
        app_id: "Api",
        refresh_token: refreshToken
    };

    let fetchOptions: RequestInit = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        mode: "cors",
        body: new URLSearchParams(body)
    }

    let msLogin = await fetch(`${meetingBoosterUrl}/oauth`, fetchOptions);

    if (msLogin.ok) {
        let fetchJson = await msLogin.json();
        saveMBToken(fetchJson);

        return fetchJson;
    } else {
        var errorMessage = await msLogin.text();
        console.error(errorMessage);
        throw new Error("MeetingBooster could not authorize. Invalid AD access token.");
    }
}

async function authorizeInMeetingBooster(accessToken: string, meetingBoosterUrl: string) {
    let fetchOptions: RequestInit = {
        method: 'POST',
        headers: {
            'Content-Type': 'text/plain'
        },
        mode: "cors",
        body: accessToken
    }

    let msLogin = await fetch(`${meetingBoosterUrl}/api/v1/authorize/mslogin`, fetchOptions);

    if (msLogin.ok) {
        try {
            let fetchJson = await msLogin.json();
            saveMBToken(fetchJson);
            return fetchJson;
        } catch (error) {
            var errorMessage = await msLogin.text();
            console.error(errorMessage);
            throw new Error("Could not parse JSON from mslogin.");
        }
    } else {
        var errorMessage = await msLogin.text();
        console.error(errorMessage);
        throw new Error("MeetingBooster could not authorize. Invalid AD access token.");
    }
}

export async function verifyMeetingBoosterUrl(url: string) {
    return new Promise<boolean>(async (resolve, reject) => {
        // Add a check if this token was received from cache. As if it is not, that is verification enough
        try {
            let meetingBoosterToken = await getMeetingBoosterToken(url);

            let fetchOptions: RequestInit = {
                headers: new Headers({
                    'Authorization': `Bearer ${meetingBoosterToken.access_token}`,
                    'Content-Type': 'application/json'
                })
            }

            //let fetchResponse = await fetch(`${url}/api/v1/users/get?email=${context.userPrincipalName}`, fetchOptions);
            let fetchResponse = await fetch(`${url}/api/internal/internalapi/me`, fetchOptions);

            if (fetchResponse.ok) {
                resolve(true);
            } else {
                resolve(false);
            }
        } catch (error) {
            reject(error);
        }

    });
}

function saveMBToken(mbToken: any) {
    const expiresIn = parseInt(mbToken.expires_in);
    const epochNow = Math.floor(Date.now() / 1000);
    const tokenExpireEpoch = epochNow + expiresIn;
    mbToken.expires_epoch = tokenExpireEpoch;
    issuedMBToken = mbToken;
}