import { Card, Flex, Text } from '@fluentui/react-northstar';
import styles from './styles/topic.module.scss';
import React from 'react';
import moment from 'moment';

interface AppProps {
    topic: TopicDTO,
    selected: boolean,
    onClick: () => void
}

export default function Topic(props: AppProps) {
    function isSubTopic() {
       return props.topic.Numbering.split(".", 2)[1] !== "";
    }

    let startTime = moment(props.topic.StartTimeISO);
    let endTime = moment(props.topic.StartTimeISO).add(props.topic.Duration, "seconds")
    let time = "";

    if (props.topic.Duration !== 0) {
        time = `${startTime.format("HH:mm")} - ${endTime.format("HH:mm")}`;
    }


    return (        
        <div className={isSubTopic() ? styles.subtopic : ""}>
            <Card styles={{ cursor: "pointer" }} fluid selected={props.selected} variables={{ margin: '5px 0 5px 0' }} onClick={() => props.onClick()}>
                <Card.Body>
                    <Flex column space="between" gap="gap.small">
                        <Text content={`${props.topic.Numbering} ${props.topic.Title}`} weight="semibold" />
                        <Flex space="between">
                            <Text content={time} className={styles['topic-metadata']} />
                            <Text content={props.topic.PresenterNames} className={styles['topic-metadata']} />
                        </Flex>
                    </Flex>
                </Card.Body>
            </Card>
        </div>
    );
}