import React, { useState } from 'react'
import { RetryIcon, Tooltip } from '@fluentui/react-northstar';
import reloadStyles from './styles/reloadIcon.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
    onClick?: () => void
}
export default function ReloadIcon(props: Props) {
    const [retryIconHover, setRetryIconHover] = useState(true);
    const { t } = useTranslation();

    return (
        <div>
            <Tooltip
                trigger={
                    <RetryIcon outline={retryIconHover}
                        className={reloadStyles.retryIcon}
                        size={'large'}
                        onMouseOver={() => setRetryIconHover(false)}
                        onMouseOut={() => setRetryIconHover(true)}
                        onClick={() => props.onClick?.()}
                    />
                }
                content={t('reload-text')}
            />
        </div>
    )
}
